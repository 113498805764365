<template>
  <div>
     <div class=" card card-content" v-if="selected && selected !== '0'" style="padding: 0!important">
        <img src="@/assets/unsplash_pElSkGRA2NU.png"/>

          <p class="title-budget"> Plan de développement des compétences </p>

       <div style="padding:24px; padding-top:0" >

        
          <p class="content is-medium" style="font-weight: bold" v-if="selected">Votre budget </p>

          <div v-if="selected && selected.budget" class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large; margin-right:18px" class="fa-solid fa-coins"></i>  Vous disposez d'un budget de : </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.budget && selected.budget.toLocaleString() }}€ </p> 
                <p class="content is-small" style="text-align: right;font-style : italic;"> HT pour l'année </p>
            </div>
          </div>

          <div v-if="selected && selected.hourlyCap && selected.hourlyCap !== '0' && selected.hourlyCap !== '-' " class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large;margin-right:18px" class="fa-regular fa-clock"></i>  Un plafond horaire à :  </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.hourlyCap }}€/h </p> 
                <p class="content is-small" style="text-align: right;font-style : italic;"> HT pour l'année </p>
            </div>
          </div>

          <p style="font-style: italic; font-weight :bold; margin-left :50px">{{selected.broadcastable !== "En externe" ? "Ce budget est susceptible de ne plus être exact." : ""}}</p>

          <p style=" margin-bottom : 35px; margin-left :45px">
          Dispositif permanent. Budget annuel par entreprise pouvant être revu à la hausse ou à la baisse en cours d'année.
Perdu si pas consommé. Renouvelé chaque année. </p>


          <div v-if="selected && selected.dailyCap !== '0' && selected.dailyCap && selected.dailyCap !== '-' " class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large;margin-right:18px" class="fa-regular fa-calendar-check"></i>  Un plafond journalié à :  </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.dailyCap }}€/jours </p> 
                <p class="content is-small" style="text-align: right;font-style : italic;"> HT pour l'année </p>
            </div>
          </div>


          <div v-if="selected && selected.details" class="content">
            <p style=" margin-bottom : 35px; margin-left :50px">
            Information complémentaire : {{ selected.details }} </p>
          </div>

            
          <div class="content">
            <i style="font-size: x-large;position: absolute;margin-top:10px" class="fa-regular fa-calendar-days"> </i> 
            <div style="margin-left: 45px">
              <p style="margin-bottom :0 !important;">Date de mise à jour : <span style="font-weight: bold;"> {{ new Date(selected.updateDate).toLocaleDateString() }} </span> </p>
              <p>Date de mise à jour de l'opco : <span style="font-weight: bold;">{{ new Date(selected.updateOcpoDate).toLocaleDateString() }} </span> </p> 
            </div>
            <b-button
                v-if="admin"
                label="✐"
                class="button_update"
                @click="confirm" />
          </div>




       </div>
    </div>
    <div class=" card card-content" style="margin-top:15px">

          <p style="font-weight :bold; margin-bottom : 35px"><i style="padding-top:10px;font-size:x-large;margin-right:23px" class="fa-solid fa-square-envelope"> </i>Vous souhaitez être informé ? </p>

          <b-field>
            <b-checkbox type="is-info" value="true">Je souhaite recevoir les résultats de ma recherche</b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox type="is-info">Je souhaite recevoir les mise à jour au fil du temps de ma recherche</b-checkbox>
          </b-field>

          <div style="margin-top: 30px;">
            <form style="">
              <b-field>
                <b-input placeholder="Email..."
                        icon-pack="fas"
                        icon="at"
                        v-model="email"
                        rounded
                        class="input-emailSender"
                >
                </b-input>
                <p style="">
                  <b-button native-type="submit" class="button-emailSender" @click="sendEmailInformation" label="Recevoir" style="" />
                </p>
              </b-field>
            </form>
          </div>

</div>
  </div>
</template>

<script>
import {sendEmail} from "@/utils/email/sendEmail";
import {getCookie} from "@/utils/cookies";
import {updateIdcc} from "@/utils/idcc/updateIdcc";
export default {
  name: "BudgetComponent",
  props: {
    selected: {
      minEmployees : Number,
      maxEmployees : Number
    },
    results: {
      nom:String,
      forme_juridique:String,
      date_creation:Date
    },
  },
  data() {
    return {
      email : "",
      admin: false
    };
  },

  mounted() {
    if (getCookie('token')) {
      this.admin = true
    }
  },

  methods:{
    sendEmailInformation(){
      event.preventDefault()
      try {
        if(this.email.length > 5){
          const text = 'Bonjour ! \nVoici vos informations pour l\'entreprise : '+ 
          this.results.forme_juridique.split(',')[0] + ' - ' + this.results.nom + ', crée le ' + 
          new Date(this.results.date_creation).toLocaleDateString() +'\n\nVous disposez d\'un budget de : ' + this.selected.budget +
          ' € ht pour l\'année' + 
          '\nN\'hésitez pas a nous contacter pour en savoir plus' 
          sendEmail(this.email, text).then(res =>{
            console.info(res);
            this.notificationSuccess("Mise à jour de la date confirmé ! 🙂")
          })
        }
      }catch (e) {
        console.log(e)
      }
    },
    confirm() {
        this.$buefy.dialog.confirm({
            message: 'Confirmer la mise à jour Matchers de la date (vérification à date du jour)',
            onConfirm: () => {
                this.updateDateForCheckup();
                this.notificationSuccess("Mise à jour de la date confirmé ! 🙂")
              }
        })
    },

    updateDateForCheckup() {
      updateIdcc(this.selected.idcc, this.selected.minEmployees, this.selected.maxEmployees, this.selected.budget, this.hourlyCap, this.selected.details, this.selected.dailyCap, this.selected.hourlySupported,this.selected.broadcastable,this.selected.facility,
        this.selected.updateOcpoDate,this.selected.generalAfest,this.selected.AfestHourlyCap,this.selected.hourlyVolumeCap,this.selected.afestHourlyVolumeCap,this.selected.minHourlyVolume,this.selected.capRateOfCoverage,
        this.selected.budgetTPE,this.selected.generalAfestTPE,this.selected.hourlyCapTPE,this.selected.minHourlyVolumeTPE,this.selected.capRateOfCoverageTPE,this.selected.conditionsTPE,this.selected.budgetFNE,this.selected.deadLineTPE,this.selected.salaryEuroPerHour,
        this.selected.restauration,this.selected.hosting,this.selected.transport,this.selected.exclusion,this.selected.rh,this.selected.poec,this.selected.poei,this.selected.other, this.selected._id).then(res=>{
        console.info(res);
        }).catch(e=>{
          this.notificationError(e)
        }).finally(()=>{
          this.selected.updateDate = new Date()
        })
    }

  }
}
</script>
<style>
p {
  font-family: 'Montserrat Alternates', sans-serif;
}
.b-checkbox.checkbox .control-label {
    padding-left: calc(1.45em - 1px);
}
.input-emailSender {
  font-family: "Montserrat Alternates", sans-serif;
  color: rgb(255, 255, 255) !important;
  width : 50vw;
  max-width: 350px;
}
.button-emailSender {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 82px !important;
    height: 40px;
    background: #FF8A55;
    color: rgb(255, 255, 255) !important;
    border-radius: 0px 36px 36px 0px;
}
.title-budget {
  position: relative;
  width: 307px;
  left: 10px;
  top: -170px;  
  font-family: "Montserrat Alternates", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;  
  color: #ffffff;
}
.button_update {
    font-family: "Montserrat Alternates", sans-serif !important;
    font-weight: bold !important;
    font-size: medium !important;
    width: 40px !important;
    height: 40px !important;
    background-color: rgba(168, 158, 158, 0.837) !important;
    position: relative;
    left: 388px;
    bottom: 43px;
}
</style>