import axios from "axios";
import {tokenHeaders} from "../http_common";
let burl = process.env.VUE_APP_API;


export function updateIdcc(idcc, changement,forAllOpco, forAllIdcc, opco, id){
    console.log({changement})
    return axios.put(burl + '/api/idcc/update?id=' + id,{
       idcc, changement, forAllOpco, forAllIdcc, opco
    },{
        headers : tokenHeaders
    });
}
