<template>
  <div>
    <b-field style="text-align:center" label="Votre convention collective">
      <b-select class="has-text-centered" placeholder="Choix de la convention collective" v-model="idcc" @change.native="onChange()">
        <option
            v-for="cc in results.cc"
            :value="cc"
            :key="cc.idcc">
          ({{ cc.idcc }}) - {{ cc.nom ? cc.nom : cc.ccName }}
        </option>
        <option value="null">Pas dans la liste ?</option>
      </b-select>
    </b-field>

    <div v-if="idcc === 'null'">
      <AutocompleteIdcc @onChange="handleIdccChange"></AutocompleteIdcc>
    </div>

  </div>
</template>

<script>
import {getOpcoByIdcc} from "@/utils/opco/getOpcoByIdcc";
import AutocompleteIdcc from "@/components/AutocompleteIdcc";
export default {
  name: "SelectorIdcc",
  components: {
    AutocompleteIdcc,
  },
  props: {
    results: {
      cc: {
        idcc: String,
        nom: String,
      },
    },
  },
  data() {
    return {
      idcc: this.results.cc.length === 1 ? this.results.cc[0] : null,
      idcc_value: null,
      convco:null
    };
  },


  methods: {
    handleIdccChange(idcc){
      this.idcc_value = idcc
      this.onChangeValue()
    },
    onChange() {
      this.getOpcoByIdcc()
      this.$emit('idccChange', this.idcc);
    },
    onChangeValue() {
      //this.getOpcoByIdcc()
      this.$emit('idcc_valueChange', this.idcc_value);
    },
    getOpcoByIdcc(){
      const idcc = this.idcc.idcc
      getOpcoByIdcc(idcc).then(res => {
        if(idcc && idcc.length>3 && res.data[0] === undefined){
          this.convco = {opcoName : "Nous ne connaissons pas l'opco de cet idcc merci de nous contacter."}
          this.$emit('opco', this.convco)
        }else{
          this.convco=res.data[0]
          this.$emit('opco', res.data[0])
        }

      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
<style>
.select {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
select {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #e6880e;
    right: 1.125em;
    z-index: 4;
}
select:not(.is-multiple):not(.is-loading)::after {
    border-color: #e6880e;
    right: 1.125em;
    z-index: 4;
}
</style>


