<template>
  <div>
     <div class=" card card-content" v-if="selected && selected !== '0'">

          <p class="content is-medium" style="font-weight: bold" v-if="selected">Dispositif TPE/PME</p>

          <div v-if="selected && selected.budgetTPE" class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large; margin-right:18px" class="fa-solid fa-coins"></i>  Vous disposez d'un budget de : </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.budgetTPE && selected.budgetTPE.toLocaleString() }}€ </p> 
                <p class="content is-small" style="text-align: right;font-style : italic;"> HT pour l'année </p>
            </div>
          </div>

          <div v-if="selected && selected.hourlyCapTPE && selected.hourlyCapTPE !== '0' && selected.hourlyCapTPE !== '-' " class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large;margin-right:18px" class="fa-regular fa-clock"></i>  Un plafond horaire à :  </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.hourlyCapTPE }}€/h </p> 
                <p class="content is-small" style="text-align: right;font-style : italic;"> HT pour l'année </p>
            </div>
          </div>

          <p style="font-style: italic; font-weight :bold; margin-left :50px">{{selected.broadcastable !== "En externe" ? "Ce budget est sucseptible de ne plus être exact." : ""}}</p>


          <div v-if="selected && selected.capRateOfCoverageTPE !== '0' && selected.capRateOfCoverageTPE && selected.capRateOfCoverageTPE !== '-' " class="content columns">
            <div class="column">
              <p><i style="padding-top :10px;font-size: x-large;margin-right:18px" class="fa-regular fa-calendar-check"></i> Taux de prise en charge : </p>
            </div>
            <div class="column">
              <p style="text-align: right; margin-bottom: 0; font-weight: bold; font-size: x-large">  
                {{ selected.capRateOfCoverageTPE }} </p> 
            </div>
          </div>


          <div v-if="selected && selected.conditionsTPE" class="content">
            <p style="margin-left :45px">
            Conditions : {{ selected.conditionsTPE }}</p>
          </div>

        
    </div>

    <div class=" card card-content" v-if="selected && selected !== '0'" style="margin-top :15px">
      <div v-if="selected && selected.other" class="content">
        <p class="content is-medium" style="font-weight: bold" >Autre dispositifs</p>

        <p style=" margin-bottom : 35px; margin-left :30px">
            D'autres dispositifs peuvent être mobilisés.</p>

        <ul v-for="(other, index) of selected.other.split('\n')" :key=index>
          <li style="margin-left :30px"> {{other}} </li> 
        </ul>

        <div style="text-align: center">
           <a class="button-other" href="https://meetings.hubspot.com/ssilvi" target="_blank">Prenez rendez-vous ! </a>
        </div>
        
      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: "OtherDispositifComponent",
  props: {
    selected: {
      minEmployees : Number,
      maxEmployees : Number
    },
  },
  data() {
    return {
      email : "",
    };
  },

  methods:{
    
  }
}
</script>
<style>
.button-other {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: bold;
  font-size: medium;
  background: #FF8A55;
  color: rgb(255, 255, 255) !important;
  border-radius: 10px;
  padding: 10px;
}
</style>