<template>
  <div style="margin-bottom:30px">
     <div class="card card-content" style="padding: 0!important; margin:0 !important"> 

      <div class="columns">
        <div class="column is-two-fifths image-info"> </div>

        <div class="column is-vcentered" style="text-align: center;padding:24px;">
          <p class="content is-medium" style="font-weight: bold">Vous souhaitez vous former ? </p>
          <p class="content is-small" >Nos formations finançables à 100%
Mettez en place des formations personnalisées et productives
pour vos équipes. :muscle:  </p>
          <div >
            <b-button label="En savoir plus !" @click="$router.push('/funnel/')"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "InformationFunnel",
  data() {
    return {
      email : "",
    };
  },

  methods:{
    
  }
}
</script>
<style>
p {
  font-family: 'Montserrat Alternates', sans-serif;
}
.button-emailSender {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 95px;
    height: 40px;
    background: #FF8A55;
    color: rgb(255, 255, 255) !important;
    border-radius: 0px 36px 36px 0px;
}

.button {
  font-family: "Montserrat Alternates", sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 150px;
    height: 40px;
    background: #FF8A55;
    color: rgb(255, 255, 255) !important;
}

.image-info {
  background: center / contain no-repeat url("https://images.pexels.com/photos/3153198/pexels-photo-3153198.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"),#ffffff 60%;
  padding-left: inherit;
}

</style>