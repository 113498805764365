<template>
  <div>
    <form>
      <div class="card card-content">
        <p class="content is-medium" style="font-weight: bold" v-if="selected">Votre budget </p>
        <p>Pour connaitre votre budget formation, les experts Matchers ont besoin de faire quelques recherches. Vous serez ensuite recontacté par mail sous 24 heures avec le détail de vos droits.</p>
                        <p>Pensez bien à remplir toutes les informations demandées ! 🙂</p>

          <div style="margin-top: 30px;">
            <form style="">
              <b-field>
                <b-input placeholder="Email..."
                        icon-pack="fas"
                        icon="at"
                        v-model="email"
                        rounded
                        class="input-emailSender"
                >
                </b-input>
                <p style="">
                  <b-button native-type="submit" class="button-emailSender" @click="sendSlackNotification"  label="Recevoir" style="" />
                </p>
              </b-field>
            </form>
          </div>

          
      </div>
    </form>
  </div>
</template>

<script>
import {sendWebhook} from "@/utils/slackApi/sendWebhook";

export default {
  name: "EmailSender",
  props: {
    selected: {
      minEmployees : Number,
      maxEmployees : Number
    },
    results: {
      nom:String,
      forme_juridique:String,
      date_creation:Date
    },
    idcc:String,
  },
  data() {
    return {
      email : "",
    };
  },

  methods:{
    isEmailValid() {
      const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.email.trim().length > 5 && emailReg.test(this.email.trim());
    },

    sendSlackNotification(){
      event.preventDefault()
      try {
        const idcc = this.idcc
        const text = 'Hey ! \nL\'utilisateur de l\'entreprise : '+ this.results.forme_juridique.split(',')[0] + ' - ' + this.results.nom + ', crée le ' + new Date(this.results.date_creation).toLocaleDateString() +'\nqui a pour email : ' + this.email + '\nvoudrait connaître son budget de formation ! \nVoici les informations à savoir : ' +
            '\n - IDCC : ' + idcc + '\n - Effectif : de ' + this.selected.minEmployees +' à ' + this.selected.maxEmployees
        
        this.notificationSuccess("Super ! vous recevrez le détail de tous les dispositifs mobilisables sous 24h. Si vous avez d’ores et déjà des questions, nos équipes sont disponibles\n 🙂")
        sendWebhook(text).then(res=>{
          console.info(res);
          this.notificationSuccess("Super ! vous recevrez le détail de tous les dispositifs mobilisables sous 24h. Si vous avez d’ores et déjà des questions, nos équipes sont disponibles\n 🙂")
        })
      }catch (e) {
        console.log(e)
      }
    },
  }
}
</script>