<template>
  <div>
    <Navbar text="Voici votre budget formation entreprise ✍️"/>
    <div class="card card-content container is-max-desktop is-max-widescreen content is-vcentered" style="margin-top: 0; padding-top: 50px; font-family: 'Montserrat Alternates', sans-serif">
      <div>
        <p class="has-text-centered" style="font-family: 'Montserrat Alternates', sans-serif; color: black; font-weight: bold; font-size: x-large"><i class="far fa-building fa-spin"></i> {{results && results.forme_juridique && results.forme_juridique.split(',')[0] ? results.forme_juridique.split(',')[0] : '' }} - {{ results && results.nom ? results.nom : ''}}</p>

<!--        <p><i class="far fa-calendar"></i> {{ new Date(results.date_creation).toLocaleDateString() }}</p>-->
      </div>

      <br/>

      <div v-if="results && results.cc && results.cc.length>=1" style="margin-bottom: 10px" >

        <SelectorIdcc :results="results" @idcc_valueChange="handleIdccValueChange" @idccChange="handleIdccChange" @opco="opcoName"/>

      </div>

      <div v-if="idcc && idcc !== 'null' || idcc_value && idcc_value.length === 4" style="margin-bottom: 15px">
        <SelectorEmployees @selectChange="handleChange" @employeesNumberChange="handleEmployeesNumber"
                           :informationIdcc="informationIdcc"/>
      </div>

      <div v-if="selected" style="margin-top: 50px; margin-bottom: 10px; text-align: center">
        <p class="content is-medium">Vous dépendez de l’organisme financeur : <span v-if="opco && opco.opcoName" style="font-weight : bold"> {{opco.opcoName}}</span>   </p>
      </div>

      <!-- <a v-if="opco" class="matchers" :href="ocpo.dataLink" target="_blank">   <i class="fa-solid fa-arrow-up-right-from-square"></i> </a> -->
  


      <div class="columns" style="margin-top:20px">
        <div class="column is-1"> </div>
        <div class="column" v-if=" selected && selected.budget">

          <BudgetComponent :selected="this.selected" :results="results" />
          <!-- <ExceptionalDispositif :selected="this.selected" /> -->

          <!-- <div class=" card card-content" v-if="selected && selected !== '0'">

          <p class="content is-medium" style="font-weight: bold" v-if="selected">Votre budget </p>
          {{selected.broadcastable !== "En externe" ? "⚠️Ce budget est sucseptible de ne plus être exact." : ""}}

                <div v-for="(my_obj, index) in Object.values(selected).slice(5)" :key="index">
                    {{my_obj && my_obj !== '-' ? my_obj : ''}}
                  </div>

          <div v-if="!updating && selected && selected.budget" class="content is-medium">
            <i class="far fa-credit-card"></i> Vous disposez d'un budget de : <p style="line-height: 29px;
text-align: right;"> <span
              style="font-weight: bold; font-size: larger">{{ budget && budget.toLocaleString() || selected.budget && selected.budget.toLocaleString() }}€</span> HT pour l'année 2021 </p>
            <br/>
          </div>
          <div v-else-if="updating">
            <b-field label="Montant du plan de développement">
              <b-input v-model="budget" :placeholder="selected ? selected.budget : '' "></b-input>
            </b-field>
            <br/>
          </div>

          <div v-if="!updating && selected && selected.hourlyCap && selected.hourlyCap !== '0' && selected.hourlyCap !== '-' " class="content is-medium">
            Un plafond horaire à : <span
              style="font-size: large; font-weight: bold">{{ dailyCap || selected.hourlyCap }}</span> € HT/h
            <br/>
          </div>

          <div v-else-if="updating">
            <b-field label="Plafond horaire">
              <b-input v-model="hourlyCap" :placeholder="selected ? selected.hourlyCap : '' "></b-input>
            </b-field>
            <br/>
          </div>

          <div v-if="!updating && selected && selected.dailyCap !== '0' && selected.dailyCap && selected.dailyCap !== '-' " class="content is-medium">
            Un plafond journalié à : <span
              style="font-size: large; font-weight: bold">{{ dailyCap || selected.dailyCap }}</span> € HT/jours
            <br/>
          </div>

          <div v-else-if="updating">
            <b-field label="Plafond journalier">
              <b-input v-model="dailyCap" :placeholder="selected ? selected.dailyCap : '' "></b-input>
            </b-field>
            <br/>
          </div>


          <div v-if="!updating && selected && selected.details" class="content is-medium">
            Information complémentaire : {{ details || selected.details }}
          </div>

          <div v-else-if="updating">
            <b-field label="Explications supplémentaire">
              <b-input maxlength="200" type="textarea" v-model="details"
                       :placeholder="selected ? selected.details : '' "></b-input>
            </b-field>
            <br/>
          </div>

          <p><i class="far fa-calendar"></i> Date de mise à jour : {{ new Date(selected.updateDate).toLocaleDateString() }} - Date de mise à jour de l'opco : {{ new Date(selected.updateOcpoDate).toLocaleDateString() }}</p>


          <b-button v-if="selected && admin" rounded @click="updateIdccDisplaying()">✏️</b-button>
          <b-button v-if="selected && admin && updating" rounded @click="updateIdcc()">Valider la mise à jour</b-button>
        </div> -->

        </div> 

        <div class="column" v-else>
          <EmailSender :hidden="!selected"
                      :selected="this.selected"
                      :idcc="this.idcc_value ? this.idcc_value : this.results && this.results.cc ? this.results.cc[0].idcc : ''"
                      :results="results"
          />
        </div>

        <div class="column" v-if="selected && selected !== '0'">
          <InformationFunnel/>

          <div v-if="selected && selected.budgetTPE" style="margin-top: 15px; margin-bottom: 20px">
            <OtherDispositifComponent :selected="this.selected" />

          </div>

          <!-- <div v-if="selected" class="content is-vcentered" style="text-align-last: center">
            <b-button native-type="submit" type="is-primary" @click="cardModal" label="Nous contacter" />
          </div> -->


        </div>

        <div class="column is-1"> </div>


      </div>

      


      

      <br/>

      <br/>
      <br/>
      <br/><br/>

    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import EmailSender from "@/components/EmailSender";
import BudgetComponent from "@/components/BudgetComponent";
// import ExceptionalDispositif from "@/components/ExceptionalDispositif";
import OtherDispositifComponent from "@/components/OtherDispositifComponent";
import InformationFunnel from "@/components/InformationFunnel";
import SelectorEmployees from "@/components/SelectorEmployees";
import SelectorIdcc from "@/components/SelectorIdcc";
import {getCookie} from "@/utils/cookies";
import {getinformationsByIdcc} from "@/utils/idcc/getInformationsByIdcc";
import {updateIdcc} from "@/utils/idcc/updateIdcc";
import {getOpcoByIdcc} from "@/utils/opco/getOpcoByIdcc";

export default {
  name: "Results",
  components: {
    Navbar,
    Footer,
    EmailSender,
    BudgetComponent,
    InformationFunnel,
    OtherDispositifComponent,
    // ExceptionalDispositif,
    SelectorEmployees,
    SelectorIdcc
  },
  data() {
    return {
      companyName: "",
      informationIdcc: "",
      idcc: null,
      idcc_value: null,
      email: null,
      text: null,
      results: '',
      selected: null,
      admin: false,
      updating: false,
      budget: null,
      hourlyCap: null,
      dailyCap: null,
      hourlySupported: null,
      details: null,
      minEmployees: null,
      maxEmployees: null,
      updatedIdcc: null,
      opco: null,
    };
  },


  async beforeMount() {
    //this.results = JSON.parse(getCookie("resCompany"))
    this.results = this.$store.state.informations.length ? this.$store.state.informations : JSON.parse(getCookie("resCompany"))
    if (this.results && this.results.cc && this.results.cc.length === 1) {
      this.idcc = this.results && this.results.cc && this.results.cc[0].idcc ? this.results.cc[0].idcc : '1486'
      this.getInfoByIdcc(this.results.cc[0].idcc)
    }
    this.opco = await this.getOpco(this.idcc)

    if (getCookie('token')) {
      this.admin = true
    }
    if(!this.opco){
      this.opco = {opcoName : "Nous ne connaissons pas l'opco de cet idcc merci de nous contacter."}
    }
  },

  watch: {
    idcc: async function () {
      this.getInfoByIdcc(this.idcc.idcc)
      await this.getOpco(this.idcc.idcc)
    },
    idcc_value: async function () {
      this.getInfoByIdcc(this.idcc_value)
      await this.getOpco(this.idcc_value)
    },

  },
  methods: {

    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: '',
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },

     getOpco(idcc){
      return getOpcoByIdcc(idcc).then(res => {
          if(res.data[0] === undefined){
            console.log('idcc is undefiend')
          }else{
            this.opco=res.data[0]
          }
          return this.opco

        }).catch(e => {
          console.log(e)
        })
    },

    getInfoByIdcc(idcc) {
      if(idcc){
        getinformationsByIdcc(idcc.trim()).then(res => {
          this.informationIdcc = res.data.sort(function (a, b) {
            return parseFloat(a.minEmployees) - parseFloat(b.minEmployees);
          });

        }).catch(e => {
          console.log(e)
        })
      }
    
    },

    updateIdccDisplaying() {
      this.updating = true;
      this.idcc = this.selected.idcc;
      this.minEmployees = this.selected.minEmployees;
      this.maxEmployees = this.selected.maxEmployees;
      this.budget = this.selected.budget;
      this.hourlyCap = this.selected.hourlyCap;
      this.dailyCap = this.selected.dailyCap;
      this.hourlySupported = this.selected.hourlySupported;
      this.details = this.selected.details;
    },

    updateIdcc() {
      updateIdcc(this.idcc, this.minEmployees, this.maxEmployees, this.budget, this.hourlyCap, this.details, this.dailyCap, this.hourlySupported, this.selected._id).then(res => {
        this.notificationSuccess("Idcc mis à jour avec succes")
        this.updatedIdcc = res.data
        this.updating = false
      }).catch(e => {
        this.notificationError(e)
      }).finally(() => {
        this.idcc = this.updatedIdcc.idcc;
        this.minEmployees = this.updatedIdcc.minEmployees;
        this.maxEmployees = this.updatedIdcc.maxEmployees;
        this.budget = this.updatedIdcc.budget;
        this.hourlyCap = this.updatedIdcc.hourlyCap;
        this.dailyCap = this.updatedIdcc.dailyCap;
        this.hourlySupported = this.updatedIdcc.hourlySupported;
        this.details = this.updatedIdcc.details;
        this.selected = this.updatedIdcc
      })
    },

    handleChange(selected) {
      this.selected = selected
    },

    handleIdccChange(idcc) {
      this.idcc = idcc
    },

    handleIdccValueChange(idcc_value){
      this.idcc = idcc_value
    },

    handleEmployeesNumber(employees) {
      this.employeesNumber = employees
      this.selected = {
        minEmployees : employees,
        maxEmployees : employees
      }
    },
    opcoName(opco){
      this.ocpo = opco
    }

  }
}
</script>
