<template>
  <div>

    <div v-if="informationIdcc">
      <b-field style="text-align:center; margin-top:25px" label="Le nombre de salariés de votre entreprise (CDI, CDD, alternants)">
        <b-select class="has-text-centered" placeholder="Selectionne le nombre de salariés" v-model="selected" @change.native="onChange()">
          <option value="0">0 salarié</option>
          <option
              v-for="(info, index) in nonNullInformationIdcc"
              :value="info"
              :key="index"
          >
            de {{ info.minEmployees }} à {{ info.maxEmployees }} salariés
          </option>
          <option value="null">Pas dans la liste ?</option>
        </b-select>
      </b-field>
    </div>

    <div v-if="selected==='null' || !informationIdcc">

      <div class="columns" style="margin-top:10px">
        <div class="column is-3"> </div>
        <div class="column"> 
           <b-field label="Entrez le nombre de salariés de votre entreprise (CDI, CDD, alternants)" style="text-align:center">
              <b-input class="input-employee" placeholder="Nombre de salariés ?"
                      min="0"
                      max="1000"
                      type="Number"
                      v-model="employees"
                      style="margin-bottom: 10px"
                      @input.native="onEmployeeNumberChange"
              >
              </b-input>
            </b-field>
        </div>
        <div class="column is-3"> </div>
      </div>

     
    </div>


  </div>
</template>

<script>
export default {
  name: "EmailSender",
  props: {
    informationIdcc: {
      budget : Number,
      minEmployees : Number,
      maxEmployees : Number
    },
  },
  data() {
    return {
      selected : null,
      employees : null,
    };
  },

  computed: {
    nonNullInformationIdcc: function() {
      return this.informationIdcc.filter(function(item) {
        return item.minEmployees !== null;
      });
    }
  },

  methods:{
    onChange(){
      this.$emit('selectChange', this.selected);
    },
    onEmployeeNumberChange(){
      this.$emit('employeesNumberChange', this.employees)
    }
  }
}
</script>
<style>
.select {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
select {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #e6880e;
    right: 1.125em;
    z-index: 4;
}
select:not(.is-multiple):not(.is-loading)::after {
    border-color: #e6880e;
    right: 1.125em;
    z-index: 4;
}
.input-employee {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}


</style>