import axios from "axios";
let burl = process.env.VUE_APP_API;
import {shieldHeaders} from "../http_common";


export function getinformationsByIdcc(idcc){
    return axios.get(burl + '/api/idcc/?idcc=' + idcc,{
        headers : shieldHeaders
    });
}
