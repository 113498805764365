import axios from "axios";
let burl = process.env.VUE_APP_API;
import {shieldHeaders} from "../http_common";

export function sendEmail(email, text){
    return axios.post(burl+ '/api/email/',{
        email,
        text
    },{
        headers : shieldHeaders
    });
}
