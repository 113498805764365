import axios from "axios";
let burl = process.env.VUE_APP_API;
import {shieldHeaders} from "../http_common";

export function sendWebhook(text){
    return axios.post(burl+ '/api/slack/',{
        text
    },{
        headers : shieldHeaders
    });
}


